<template>
<div
        class="table__line"
        :class="line.color ? `table__line--${line.color}` : ''"
      >
        <div
          v-for="col in schema.structure"
          :key="col.slug"
          class="table__col"
          :class="[
            `table__col--${col.width}`,
            line[col.slug] && line[col.slug].color
              ? `table__col--${line[col.slug].color}`
              : '',
          ]"
        >
          <div
            v-if="col.type === 'text'"
            v-html="
              typeof line[col.slug] === 'object'
                ? line[col.slug]?.value
                : line[col.slug]
            "
          ></div>
          <div
            v-if="col.type === 'bool'"
            :class="line[col.slug] ? 'table__col--green' : 'table__col--red'"
          >
            {{ line[col.slug] }}
          </div>
          <div
            v-if="col.type === 'form'"
            class="table__form"
            style="width: 100%"
          >
            <form-generator
              :form="line[col.slug].form"
              @formData="(val) => handleFormData(val, line[col.slug].item)"
            ></form-generator>
          </div>
          <template v-if="col.type === 'textClick'">
            <button
              v-if="line[col.slug].label"
              class="btn__text"
              @click="line[col.slug].func(line[col.slug].funcArgs)"
              style="text-align: left"
            >
              {{ line[col.slug].label }}
            </button>
            <div
              v-else
              v-html="
                typeof line[col.slug] === 'object'
                  ? line[col.slug].value
                  : line[col.slug]
              "
            ></div>
          </template>
          <dropdown-button
            v-else-if="col.type === 'dropdown'"
            :data="line[col.slug]"
          ></dropdown-button>
          <div
            v-else-if="Array.isArray(line[col.slug]) && col.type !== 'array'"
            class="table__col--flex"
          >
            <template v-for="(el, j) in line[col.slug]">
              <component
                :is="el.tag"
                :to="el.to ? el.to : ''"
                :key="j"
                v-if="el.type === 'btn-icon' && el.display"
                class="btn btn__action table__action--btn"
                :class="[`btn--${el.color}`, 'btn__help']"
                @click.prevent="el.func(el.funcOptions)"
              >
                <template v-if="el.tag === 'button'">
                  <loading-button class="btn__center" :loading="el.loading">
                    <SvgIcon :icon="el.icon" size="1.8"></SvgIcon>
                  </loading-button>
                </template>
                <template v-else>
                  <SvgIcon :icon="el.icon" size="1.8"></SvgIcon>
                </template>
                <span v-if="el.help" class="btn__help--span">
                  {{ el.help }}
                </span>
              </component>
              <div v-else-if="!el.display && el.displayFalse">
                {{ el.displayFalse }}
              </div>
            </template>
          </div>
        </div>
      </div>
</template>

<script setup>
import SvgIcon from "@/components/utils/SvgIcon";
import FormGenerator from "@/components/form/FormGenerator.vue";
import DropdownButton from "@/components/utils/DropdownButton.vue";
import LoadingButton from "@/components/utils/LoadingButton";

const emit = defineEmits(["updateLine"]);


const handleFormData = (val, lineData) => {
  emit("updateLine", { line: lineData, newVal: val.data });
};
const props = defineProps(['line', "schema"])

</script>